<template>
  <LoadingLayout></LoadingLayout>
</template>

<script>
import LoadingLayout from "@/layouts/LoadingLayout.vue";
import msg from "@/utils/constants/msg";

export default {
  name: "Oauth",
  components: {
    LoadingLayout
  },
  mounted() {
    if (!this.$route.query.code) {
      if (this.$store.getters["user/isAuthenticated"]) {
        this.$router.push({ path: this.loginRedirect || "regotype" }).catch(() => {});
        this.$store.dispatch("views/clearLoginRedirect");
      } else {
        this.$router.push("/login").catch(() => {});
      }
    } else {
      const url = `/nrl/api/v1/portal/login?code=${this.$route.query.code}`;
      this.$noauth
        .post(url, {})
        .then(async response => {
          this.$store.dispatch("user/login", response.data);
          if (!this.$store.state.user.profile.verified) {
            this.$router.push({ name: "verification" }).catch(() => {});
          } else if (this.loginRedirect) {
            this.$router.push({ path: this.loginRedirect }).catch(() => {});
            this.$store.dispatch("views/clearLoginRedirect");
          } else {
            this.$router.push({ name: "regotype" }).catch(() => {});
          }
        })
        .catch(() => {
          this.$store.commit(
            "views/PUSH_NOTIFICATION",
            {
              msg: msg.error.unauthorized,
              type: "warning",
              route: "home"
            },
            { root: true }
          );
          this.$router.push({ name: "home" }).catch(() => {});
        });
    }
  },
  computed: {
    loginRedirect() {
      return this.$store.getters["views/loginRedirect"];
    }
  }
};
</script>

<style scoped lang="scss"></style>
